import React from "react";
import ServicesImg from "../../../static/images/servicesmain.jpg";

export default function servicesMain() {
  return (
    <div>
      <section
        className=" px-4  lg:px-32 bg-white"
        style={{ fontFamily: "poppins" }}
      >
        {/* <div class="skew skew-top mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
    </svg>
  </div> */}
        <div class="skew skew-top ml-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewbox="0 0 10 10"
            preserveaspectratio="none"
          >
            <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
          </svg>
        </div>
        <div class="py-14 lg:py-10 bg-gray-50 radius-for-skewed">
          <div class="container mx-auto  lg:px-4">
            <div class="mb-10 flex flex-wrap">
              <div class="relative w-full lg:w-1/2">
                <img class="relative rounded-lg" src={ServicesImg} alt="" />
                <button class="text-white hover:text-gray-50">
                  {/* <svg class="absolute w-16 h-16" style={{top: '38%', left: '45%'}}>
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path>
            </svg> */}
                </button>
              </div>
              <div class="mb-10 lg:mb-0 w-full lg:w-1/2">
                <div class="max-w-xl pl-6 lg:pl-14">
                  <span class="text-sky-600 font-semibold">
                    Risk based analysis
                  </span>
                  <h2 class="mb-8 text-2xl lg:text-5xl font-bold font-heading text-indigo-900">
                    Bespoke solutions for new age problems
                  </h2>
                  <p class="max-w-xl text-gray-500 leading-loose">
                    RJ Actuaries & Consulting enables data driven risk
                    assessments and provide solutions through technology driven
                    applications
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap pt-10 px-5 -mx-4">
              <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  1
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Actuarial Consulting and Valuation
                </h3>
                <p class="text-gray-500 leading-loose">
                  RJ Actuaries & Consultants provides a comprehensive suite of
                  actuarial services, including actuarial valuation, risk
                  assessment, and strategic consulting.
                </p>
              </div>
              <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  2
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Employee Benefit Consulting
                </h3>
                <p class="text-gray-500 leading-loose">
                  RJ Actuaries & Consultants helps businesses evaluate and
                  review their existing employee medical benefits, providing gap
                  assessments, policy structuring, and healthcare risk
                  management.
                </p>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  3
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Enterprise Risk Management
                </h3>
                <p class="text-gray-500 leading-loose">
                  The company offers robust risk management solutions, including
                  regulatory advisory support for insurance and reinsurance
                  companies, claims reserve estimation, and scenario & stress
                  testing
                </p>
              </div>
            </div>

            <div class="flex flex-wrap pt-10 px-5 -mx-4">
              {/* <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  4
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Investment Consulting
                </h3>
                <p class="text-gray-500 leading-loose">
                  We offer services related to, <br></br>- Asset-liability
                  management <br></br>- Investment strategy development<br></br>
                  - Investment performan evaluation<br></br>
                </p>
              </div> */}
              <div class="mb-12 lg:mb-0 w-full md:w-1/2 lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  4
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Technology Solutions
                </h3>
                <p class="text-gray-500 leading-loose">
                  The company offers innovative technology solutions to support
                  clients in streamlining their operations, leveraging
                  data-driven insights, and embracing the power of machine
                  learning and AI.
                </p>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  5
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Business and Management Consulting
                </h3>
                <p class="text-gray-500 leading-loose">
                  RJ Actuaries & Consultants provides strategic business
                  consulting services, empowering clients to make informed
                  decisions and navigate the complexities of the insurance and
                  risk management industry.
                </p>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <span class="mb-6 w-12 h-12 flex justify-center items-center bg-indigo-100 rounded text-indigo-800 font-bold">
                  6
                </span>
                <h3 class="mb-2 text-xl font-semibold font-heading text-indigo-800">
                  Data Mining and Engineering
                </h3>
                <p class="text-gray-500 leading-loose">
                  RJ Actuaries & Consultants utilizes advanced data mining
                  techniques to extract valuable insights from complex datasets,
                  enabling clients to make well-informed, data-driven decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="skew skew-bottom mr-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewbox="0 0 10 10"
            preserveaspectratio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
          </svg>
        </div>
        <div class="skew skew-bottom ml-for-radius">
          <svg
            class="h-8 md:h-12 lg:h-20 w-full text-gray-50"
            viewbox="0 0 10 10"
            preserveaspectratio="none"
          >
            <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
          </svg>
        </div>
      </section>
    </div>
  );
}
